import "components/MapPro.js/Map.css";

import {
  Circle,
  GoogleMap,
  InfoBox,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";

import { API_URL } from "variables/general";
import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import {
  bmsIdContext,
  locationDataMContext,
  valueDataContext
} from "../../views/Dashboard";
import MarkerB from "assets/img/icons8-map-pin-50.png";
import MarkerA from "assets/img/icons8-map-pin-50 (1).png";
import TripMap from "./TripMap";
let dataArray = [];
let mapCenterOnDrag;
let map_center_dragged;
let dragFlag = false;
function SingleMapPro(props) {
  const mapRef = useRef(null);
  const valueData = useContext(valueDataContext);
  let locationDM = useContext(locationDataMContext);
  let locationDataM = locationDM ? locationDM : props.locationDataM;
  const date = new Date()
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth() + 1);
  const [day, setDay] = useState(date.getDate());
  const [loader, setLoader] = useState(false);
  const [show1000Text, setShow1000Text] = useState(true);
  const [geoData, setGeoData] = useState("");
  const [locationTime, setLocationTime] = useState("");
  const [position, setPosition] = useState();
  const [showTripPage, setShowTripPage] = useState(false);
  const [locationData, setLocationData] = useState('');
  const [locationDataLoaded, setLocationDataLoaded] = useState(false);
  const [locationDataLoadedFirst, setLocationDataLoadedFirst] = useState(true);
  const bmsId = useContext(bmsIdContext);
  const id = bmsId ? bmsId : props.IotId;
  const apiKey = "AIzaSyDUob5ItT5zZ_lWzJVkW9_Rz9oTq-B9M74"
  let latitude
  let longitude
  // const sTms = year + '-' + month + '-' + day + 'T00:00:01Z'
  useEffect(() => {
    handleGeoFencing();
    handleFirstMapCoordinates()
  }, []);
  function handleLoad(map) {
    mapRef.current = map;
  }
  function handleCenter() {
    if (!mapRef.current) return;
    dragFlag = true;
    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }
  const handleMapCoordinates = (tms) => {
    setShow1000Text(false)
    setLoader(true)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        did: id,
        gpsSpeed: 2,
        tms_gt: `${year}-${month}-${day}T00:00:00Z`,
        tms_lt: `${year}-${month}-${day}T23:59:59Z`,
        // limit: 1000
      }),
    }
    fetch(API_URL + "/bms/bms_location", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(false)
        setLocationData(result.data);
        locationDataLoadedFirst ?
          setLocationDataLoaded(false)
          :
          setLocationDataLoaded(true)
      })
      .catch((error) => console.log("error", error));
  };
  const handleFirstMapCoordinates = (tms) => {
    setShow1000Text(true)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        did: id,
        limit: 1000
      }),
    }
    fetch(API_URL + "/bms/bms_location", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLocationData(result.data);
        locationDataLoadedFirst ?
          setLocationDataLoaded(false)
          :
          setLocationDataLoaded(true)
      })
      .catch((error) => console.log("error", error));
  };
  function getCalenderDate(e) {
    const date = e.target.value.split('-')
    setYear(date[0])
    setMonth(date[1])
    setDay(date[2])
  }
  const handleGeoFencing = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        did: id,
      }),
    };

    fetch(API_URL + "/bms/get_geo_fencing", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGeoData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getLocationTime = (val) => {
    setLocationTime(val);
  };
  const sendLocationTime = () => {
    setLocationDataLoaded(true);
    setLocationDataLoadedFirst(true);
    handleMapCoordinates(locationTime);
  };
  const containerStyle = {
    width: "56vw",
    height: "80vh",
  };

  if (showTripPage) {
    return <TripMap id={id} setShowTripPage={setShowTripPage} showTripPage={showTripPage} locationData={locationData} evTracker={props.evTracker} />
  }
  else
    if (locationData && geoData) {
      if (locationData.bmsLocation.length > 0) {
        let polyLinePath = [];
        let positionArray;

        const circleCenter = {
          lat: geoData.length > 0 ? geoData.geoFence[0].latitude : 0,
          lng: geoData.length > 0 ? geoData.geoFence[0].longitude : 0,
        };
        const options1 = {
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "",
          fillOpacity: 0.05,
          clickable: false,
          draggable: false,
          editable: false,
          visible: true,
          radius: geoData.length > 0 ? geoData.geoFence[0].circleRadius * 1000 : 0,
          zIndex: 1,
        };
        const options2 = {
          strokeColor: "#F0F",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "",
          fillOpacity: 0.05,
          clickable: false,
          draggable: false,
          editable: false,
          visible: true,
          radius: 10,
          zIndex: 1,
        };
        const options3 = {
          strokeColor: "#0FF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "",
          fillOpacity: 0.05,
          clickable: false,
          draggable: false,
          editable: false,
          visible: true,
          radius:
            geoData.length > 0 ? (geoData.geoFence[0].circleRadius + geoData.geoFence[0].alarmRange) *
              1000 : 0,
          zIndex: 1,
        };
        let positionUsed;
        let tms;
        let startPoint = {
          lat: parseFloat(locationData.bmsLocation[0].gpsLatCoordinate),
          lng: parseFloat(locationData.bmsLocation[0].gpsLongCoordinate),
        };
        let startPointTime;
        if (locationData.bmsLocation[0].tms)
          startPointTime = locationData.bmsLocation[0].tms;
        else startPointTime = locationData.bmsLocation[1].tms;

        let endPointTime =
          locationData.bmsLocation[locationData.bmsLocation.length - 1].tms;
        for (let i = 0; i < locationData.bmsLocation.length; i++)
          if (
            locationData.bmsLocation[i].gpsLatCoordinate &&
            locationData.bmsLocation[i].gpsLongCoordinate
          ) {
            polyLinePath.push({
              lat: parseFloat(locationData.bmsLocation[i].gpsLatCoordinate),
              lng: parseFloat(locationData.bmsLocation[i].gpsLongCoordinate),
            });
          }
        for (let i = locationData.bmsLocation.length - 1; i > 0; i--)
          if (
            locationData.bmsLocation[i].gpsLatCoordinate &&
            locationData.bmsLocation[i].gpsLongCoordinate
          ) {
            positionUsed = {
              lat: parseFloat(locationData.bmsLocation[i].gpsLatCoordinate),
              lng: parseFloat(locationData.bmsLocation[i].gpsLongCoordinate),
            };
            tms = locationData.bmsLocation[i].tms;
            i = 0;
            mapCenterOnDrag = {
              lat: parseFloat(locationData.bmsLocation[i].gpsLatCoordinate),
              lng: parseFloat(locationData.bmsLocation[i].gpsLongCoordinate),
            };
          }
        if (locationDataM) {
          console.log('locationDataM', locationDataM)
          if (locationDataM.ext[0].gpsValues.gpsFixStatus)
            if (locationDataM.ext[0].gpsValues.gpsFixStatus === 1)
              dataArray.push({
                lat: parseFloat(locationDataM.ext[0].gpsValues.gpsLatCoordinate),
                lng: parseFloat(locationDataM.ext[0].gpsValues.gpsLongCoordinate),
              });
        }
        const polylineOptions = {
          strokeColor: "#32c85a",
          strokeOpacity: 1,
          strokeWeight: 5,
          fillColor: "#32c85a",
          fillOpacity: 0.35,
          clickable: false,
          draggable: false,
          editable: false,
          visible: true,
          radius: 3000,
          zIndex: 1,
        };
        if (tms) {
          positionArray = polyLinePath.concat(dataArray);


          latitude =
            locationDataM
              ? locationDataM.ext[0].gpsValues.gpsFixStatus === 1
                ?
                locationDataM.ext[0].gpsValues
                  .gpsLatCoordinate
                : positionUsed.lat
              : positionUsed.lat
          longitude =
            locationDataM
              ? locationDataM.ext[0].gpsValues.gpsFixStatus === 1
                ?
                locationDataM.ext[0].gpsValues
                  .gpsLongCoordinate
                : positionUsed.lng
              : positionUsed.lng
          const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`
          return (
            <div>
              <Row>
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <strong>Trip Mode:</strong>&ensp;
                  <label class="switch">
                    <input type="checkbox" checked={showTripPage} onChange={() => setShowTripPage(!showTripPage)}></input>
                    <span class="slider round"></span>
                  </label>
                  {geoData.length > 0 ? geoData.geoFence[0].enable ? (
                    <>
                      <strong>Circle Center:</strong> <br />
                      Lat: {geoData.geoFence[0].latitude}
                      <br />
                      Long: {geoData.geoFence[0].longitude}
                      <br /> <strong>Circle Radius: </strong>
                      <br />
                      {geoData.geoFence[0].circleRadius} Km <br />
                      <strong>Alarm Radius: </strong>
                      <br />
                      {geoData.geoFence[0].alarmRange} Km <br />
                    </>
                  ) : (
                    <>
                      <h6>Geo-fencing Disabled</h6>
                    </>
                  ) : <>
                    <h6>Geo-fencing Not Set</h6>
                  </>}
                  <br />
                  <div>
                    <strong>Select Date : </strong>
                    <br />
                    <input type="date" onChange={getCalenderDate} value={`${year}-${month}-${day}`}></input>&ensp;
                    <button onClick={handleMapCoordinates}>{loader ? 'Loading..' : 'Set'}</button>
                    <br />
                    <br />
                    {startPointTime ? (
                      <>
                        <strong>Starting Point:</strong>&ensp;
                        <span>
                          {startPointTime.slice(11, 19)}&ensp;
                          {startPointTime.slice(8, 10)}/{startPointTime.slice(5, 7)}
                          /{startPointTime.slice(0, 4)}
                        </span>
                        &ensp;
                        <img alt="startPoint" src={MarkerA}></img>
                        {show1000Text ? <><UncontrolledTooltip
                          placement="right"
                          target="showText"
                        >
                          These are last 1000 samples
                        </UncontrolledTooltip><a id='showText'><i class="fas fa-info-circle"></i></a></> : ''}
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    {endPointTime ? (
                      <>
                        <strong>End Point:</strong>&ensp;
                        {locationDataM ? locationDataM.ext[0].gpsValues.gpsFixStatus ? <span>
                          {locationDataM.tms.slice(11, 19)}&ensp;
                          {locationDataM.tms.slice(8, 10)}/{locationDataM.tms.slice(5, 7)}/
                          {locationDataM.tms.slice(0, 4)}
                        </span> : <span>
                          {endPointTime.slice(11, 19)}&ensp;
                          {endPointTime.slice(8, 10)}/{endPointTime.slice(5, 7)}/
                          {endPointTime.slice(0, 4)}
                        </span> : <span>
                          {endPointTime.slice(11, 19)}&ensp;
                          {endPointTime.slice(8, 10)}/{endPointTime.slice(5, 7)}/
                          {endPointTime.slice(0, 4)}
                        </span>}
                        &ensp;
                        <img alt="endPoint" src={MarkerB}></img>
                        <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" id='openInGoogle'>
                          <i class="fas fa-upload"></i>
                        </a>
                        <div>
                          (Last Known Location)
                        </div>
                        <UncontrolledTooltip
                          placement="right"
                          target="openInGoogle"
                        >
                          Open this location with Google Maps
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      ""
                    )}

                    <br />
                    <div>
                    </div>
                  </div>
                  {locationDataM ? (locationDataM.ext[0].gpsValues.gpsFixStatus ? <div>
                    <strong>GPS Speed:</strong>&ensp;
                    {locationDataM.ext[0].gpsValues.gpsSpeed ? Math.round(locationDataM.ext[0].gpsValues.gpsSpeed * 100) / 100 : '-'} Kmph
                    <br /><strong>No Of Satellites:</strong>&ensp;
                    {locationDataM.ext[0].gpsValues.gpsNoOfSatellites ? locationDataM.ext[0].gpsValues.gpsNoOfSatellites : '-'}
                  </div> : "") :
                    (<div>
                      <strong>GPS Speed:</strong>&ensp;
                      {locationData.bmsLocation.gpsSpeed ? locationData.bmsLocation.gpsSpeed : "-"} Kmph
                      <br />
                      <strong>No Of Satellites:</strong>&ensp;
                      {locationData.bmsLocation.gpsNoOfSatellites ? locationData.bmsLocation.gpsNoOfSatellites : '-'}
                    </div>)}
                  <br />
                  {dragFlag ? (
                    <Button
                      onClick={() => {
                        dragFlag = false;
                        setPosition(
                          locationDataM
                            ? locationDataM.ext[0].gpsValues.gpsFixStatus === 1
                              ? {
                                lat: parseFloat(
                                  locationDataM.ext[0].gpsValues.gpsLatCoordinate
                                ),
                                lng: parseFloat(
                                  locationDataM.ext[0].gpsValues.gpsLongCoordinate
                                ),
                              }
                              : positionUsed
                            : positionUsed
                        );
                      }}
                      style={{
                        borderWidth: "0px",
                        borderRadius: "10px",
                        color: "white",
                        backgroundColor: "orange",
                        fontWeight: "bolder",
                        fontSize: "12px",
                      }}
                    >
                      Recenter
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs="12" sm="12" md="8" lg="9" xl="9">
                  <div>
                    <LoadScript googleMapsApiKey={apiKey}>
                      <GoogleMap
                        onLoad={handleLoad}
                        mapContainerStyle={containerStyle}
                        center={
                          dragFlag
                            ? position
                            : locationDataM
                              ? locationDataM.ext[0].gpsValues.gpsFixStatus === 1
                                ? {
                                  lat: parseFloat(
                                    locationDataM.ext[0].gpsValues.gpsLatCoordinate
                                  ),
                                  lng: parseFloat(
                                    locationDataM.ext[0].gpsValues.gpsLongCoordinate
                                  ),
                                }
                                : positionUsed
                              : positionUsed
                        }
                        onDragEnd={handleCenter}
                        zoom={12}
                      >
                        <>
                          <Marker
                            position={
                              locationDataM
                                ? locationDataM.ext[0].gpsValues.gpsFixStatus === 1
                                  ? {
                                    lat: parseFloat(
                                      locationDataM.ext[0].gpsValues
                                        .gpsLatCoordinate
                                    ),
                                    lng: parseFloat(
                                      locationDataM.ext[0].gpsValues
                                        .gpsLongCoordinate
                                    ),
                                  }
                                  : positionUsed
                                : positionUsed
                            }
                            icon={{
                              url: MarkerB,
                            }}
                          ></Marker>
                          <Marker
                            position={startPoint}
                            icon={{
                              url: MarkerA,
                            }}
                          ></Marker>
                          <Circle center={circleCenter} options={options1} />
                          <Circle center={circleCenter} options={options2} />
                          <Circle center={circleCenter} options={options3} />
                          <Polyline
                            path={positionArray}
                            options={polylineOptions}
                            visible="true"
                          />
                        </>
                      </GoogleMap>
                    </LoadScript>
                  </div>
                </Col>
              </Row>
            </div>
          );
        } else return (
          <div>
            <Row>
              <Col xs="12" sm="12" md="4" lg="3" xl="3">
                <strong>Select Date : </strong>
                <input type="date" onChange={getCalenderDate} value={`${year}-${month}-${day}`}></input>
                <br />
                <br />
                <button onClick={handleMapCoordinates}>{loader ? 'Loading..' : 'Set'}</button>
              </Col>
              <Col xs="12" sm="12" md="8" lg="9" xl="9">
                <h4>No Location Data Yet</h4>;
              </Col>
            </Row>
          </div>)
      } else {
        return (<div>
          <Row>
            <Col xs="12" sm="12" md="4" lg="3" xl="3">
              <strong>Select Date : </strong>
              <input type="date" onChange={getCalenderDate} value={`${year}-${month}-${day}`}></input>
              <br />
              <br />
              <button onClick={handleMapCoordinates}>{loader ? 'Loading..' : 'Set'}</button>
            </Col>
            <Col xs="12" sm="12" md="8" lg="9" xl="9">
              <h4>
                No GPS Data Yet
              </h4>
            </Col>
          </Row>
        </div>)
      }
    } else {
      return (
        <div className="content">
          <div className="loadingContainer">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
          </div>
        </div>
      );
    }
}

export default SingleMapPro;
