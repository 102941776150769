import React, { useState, useRef, useContext} from 'react';
import { CSVLink } from 'react-csv';
import { API_URL } from 'variables/general'
import { bmsIdContext } from "../../views/Dashboard";
let csvDataCombined = []
function CombinedBMSDataCSV(props) {
    const batteryPackId = useContext(bmsIdContext)
    const csvLink = useRef();
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [loader, setLoader] = useState(false)
    const [showLink, setShowLink] = useState(false)
    let numberDays
    let numberDaysDone = 0
    let noDataDate = []
    const FaultArray = ["No Fault",
        "Over Pack Voltage Fault",
        "Over Pack Voltage",
        "Under Pack Voltage Fault",
        "Over OCD1 Current",
        "Over OCD2 Current",
        "Over Charge Current",
        "Over Discharge Current",
        "Short Circuit",
        "Cell Open Wire Fault",
        "Voltage Reference Fault",
        "Voltage Regulator Fault",
        "Temperature Multiplexer Error",
        "Under Temperature Fault",
        "Load Over Temperature",
        "Oscillator Fault",
        "Watchdog Timeout Fault",
        "Under Cell Voltage Fault",
        "Over Cell Voltage Fault",
        "Open Wire Fault",
        "Over Temperature Fault",
        "Cell Unbalance Pro",
        "SCD Latch High",
        "SPI Communication Fault",
        "I2C Communication Fault",
        "MCU Reset Fault",
        "Data CRC Error Fault",
        "Data Ready Fault",
        "Charger Authentication Failed",
        "Thermal Runaway Due To Temperature",
        "Thermal Runaway Due To Voltage",
        "MOSFET Drive Voltage Fault",
        "Reserve Condition Reached",
        "Battery Empty Reached",
        "Battery Full Charged Reached"]
    const getStartDate = (date) => {
        setStartDate(date.target.value)
    }
    const getEndDate = (date) => {
        setEndDate(date.target.value)
    }
    const getData = () => {
        if (startDate && endDate) {
            setShowLink(false)
            const oneDayInMs = 24 * 60 * 60 * 1000; // One day in milliseconds
            const diffInMs = Math.abs(new Date(endDate) - new Date(startDate)); // Difference in milliseconds
            const Difference = Math.ceil(diffInMs / oneDayInMs); // Convert milliseconds to days
            if (new Date(startDate) <= new Date(endDate) && (Difference < 60)) {
                const arrayDates = getDatesBetween(startDate, endDate)
                numberDays = arrayDates.length
                loopOnDates(arrayDates)
            }
            else {
                alert(`End Date should be more than Start Date and Max limit is 60 days.`)
            }
        }
    }
    function getDatesBetween(startDate, endDate) {
        let dates = [];
        let currentDate = new Date(startDate);
        while (currentDate <= new Date(endDate)) {
            // Push a copy of the current date to the array
            dates.push(new Date(currentDate));
            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }
    async function loopOnDates(dateArray) {
        setLoader(true)
        setShowLink(false)
        csvDataCombined = []
        for (const item of dateArray) {
            const year = item.getFullYear()
            let month = item.getMonth() + 1
            if (month < 10)
                month = '0' + month
            let day = item.getDate()
            if (day < 10)
                day = '0' + day
            getApiData(`${year}-${month}-${day}T00:00:00Z`)
            await sleep(1000)
        }
        setLoader(false)
        setShowLink(true)
    }
    const sleep = (time) => {
        return new Promise(resolve => setTimeout(resolve, time))
    }
    function safeStringify(obj) {
        const seen = new WeakSet();
        return JSON.stringify(obj, (key, value) => {
          if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
              return undefined; // Discard circular references
            }
            seen.add(value);
          }
          return value;
        });
      }
    function getApiData(date) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        let requestOptions;
        requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                batteryPackId: batteryPackId,
                tms: date
            }),
            redirect: "follow",
        };
        fetch(API_URL + "/bms/v2/bms_values", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                ++numberDaysDone
                if (result.data.bmsValues.length > 0) {
                    let updated_array_item_download = [];
                    result.data.bmsValues.forEach((element) => {
                        let ind_item;
                        ind_item = element;
                        let cell_temp = JSON.parse(element.cellTemp);
                        for (let i = 0; i < cell_temp.length; i++) {
                            ind_item["Cell Temp " + (i + 1)] = cell_temp[i];
                        }
                        delete ind_item["cellTemp"];
                        let cell_voltage = JSON.parse(element.cellVoltage);
                        let maxCellVoltage = cell_voltage[0];
                        let minCellVoltage = cell_voltage[0];
                        let averageCellVoltage;
                        let sumOfCellVoltages = 0;
                        for (let i = 0; i < cell_voltage.length; i++) {
                            sumOfCellVoltages = sumOfCellVoltages + cell_voltage[i];
                            ind_item["Cell Voltage " + (i + 1)] = cell_voltage[i];
                            if (maxCellVoltage < cell_voltage[i]) {
                                maxCellVoltage = cell_voltage[i];
                            }
                            if (minCellVoltage > cell_voltage[i]) {
                                minCellVoltage = cell_voltage[i];
                            }
                        }
                        ind_item["maxCellVoltage"] = maxCellVoltage;
                        ind_item["minCellVoltage"] = minCellVoltage;
                        averageCellVoltage = sumOfCellVoltages / cell_voltage.length;
                        ind_item["averageCellVoltage"] = averageCellVoltage;
                        let cellVoltageDifference = maxCellVoltage - minCellVoltage;
                        ind_item["cellVoltageDifference"] = cellVoltageDifference;
                        delete ind_item["cellVoltage"];
                        let ic_temp = JSON.parse(element.icTemp);
                        for (let i = 0; i < ic_temp.length; i++) {
                            ind_item["IC Temp " + (i + 1)] = ic_temp[i];
                        }
                        delete ind_item["icTemp"];
                        let pcb_temp = JSON.parse(element.pcbTemp);
                        for (let i = 0; i < pcb_temp.length; i++) {
                            ind_item["pcb temp " + (i + 1)] = pcb_temp[i];
                        }
                        delete ind_item["pcbTemp"];
                        let heatsink = JSON.parse(element.heatsink);
                        for (let i = 0; i < heatsink.length; i++) {
                            ind_item["Temp Cell " + (i + 1)] = heatsink[i];
                        }
                        delete ind_item["heatsink"];
                        updated_array_item_download.push(ind_item);
                    });
                    for (let k = 0; k < updated_array_item_download.length; k++) {
                        updated_array_item_download[k]["lastFault"] = FaultArray[updated_array_item_download[k]["lastFault"]]
                    }
                    // setFileName('bms_'+batteryPackId + '_' + date)
                    csvDataCombined ? csvDataCombined = [...csvDataCombined, ...updated_array_item_download] : csvDataCombined = [...updated_array_item_download]
                    // csvDataCombined.push(...updated_array_item_download)
                    // triggerDownload()
                    // console.log(csvDataCombined);

                }
                else noDataDate.push(date)
                // if (numberDaysDone === numberDays && noDataDate.length > 0) {
                //     let array = []
                //     noDataDate.forEach(element => {
                //         array.push(element.split('T')[0])
                //     });
                //     const ch = [...array]
                //     alert(`Data not found on ${ch}`)
                // }
            })
    }

    // const triggerDownload = async () => {
    //     await csvLink.current.link.click(); // Programmatically trigger the download
    // };
    if (batteryPackId)
        return (
            <div>
                <h6>Download Data Logs in CSV</h6>
                <strong>Start Date:</strong>&ensp;<input type='date' onChange={getStartDate}></input>&ensp;
                <strong>End Date:</strong>&ensp;<input type='date' onChange={getEndDate}></input>&ensp;
                <button onClick={getData}>{loader ? 'Loading...' : 'Submit'}</button>&ensp;&ensp;
                {showLink ? <CSVLink
                    data={csvDataCombined}
                    filename={batteryPackId}
                    // ref={csvLink}
                    // style={{ display: 'none' }} // Hide the link
                >Download</CSVLink> : ''}
            </div>
        )
    else return <div>Loading....</div>
}

export default CombinedBMSDataCSV