import React, { useState } from 'react'
import { Row, Col, Card, Button } from 'reactstrap'
function ChargerCalibration(props) {
  const CommandCenter = props.CommandCenter
  const integerTo4ByteArray = props.integerTo4ByteArray
  const integerTo2ByteArray = props.integerTo2ByteArray
  const byteArrayToUnsignedInt1 = props.byteArrayToUnsignedInt1
  const bitShift = props.bitShift
  const byteArrayToInt1 = props.byteArrayToInt1
  const chargerDataRecieved = props.chargerDataRecieved
  const chargerStateDataRecieved = props.chargerStateDataRecieved
  const chargerDetailsDataRecieved = props.chargerDetailsDataRecieved
  const chargerACDCParameter1DataRecieved = props.chargerACDCParameter1DataRecieved
  const byteArrayToInt2 = props.byteArrayToInt2
  const byteArrayTounsignedInt2 = props.byteArrayTounsignedInt2
  const [calCurrent, setCalCurrent] = useState('')
  const [calVoltage, setCalVoltage] = useState('')
  const dataObject = {
    currentRef: '',
    voltageRef: '',
    current: "",
    voltage: '',
  }
  const chargerStateEnum = [
    'Ideal',
    'Charging',
    'Stand By',
    'Battery Failure',
    'Charger Failure',
  ]
  const chargingModeEnum = [
    'None',
    'Precharge',
    'cc',
    'cv',
    'Charging Completed',
  ]
  const CANBaudRateEnum = [
    "125 Kbps",
    "250 Kbps",
    "500 Kbps",
    "1 Mbps",
  ]
  const chargerACDCParameter1Object = {
    acMains: '',
    temp2: '',
    temp3: '',
    tempIn: ''
  }
  const chargerStateObject = {
    CANBaudrate: '',
    chargerState: '',
    chargingMode: '',
    gfciFault: '',
    overTempFault: '',
    overCurrentFault: '',
    shortCircuitFault: '',
    overVoltageFault: '',
    underVoltageFault: '',
    acUnderVoltageFault: '',
    acOverVoltageFault: '',
    pfcFault: '',
    canCommFail: '',
    chargingHaltedFault: '',
    chargingNotStartedFault: ''
  }
  const chargerDetailsObject = {
    hardwareVer: '',
    firmwareVer: '',
    designedVoltage: '',
    designedCurrent: ''
  }
  function sendCalCurrent() {
    const canId = integerTo4ByteArray(285021514)
    const messageId = 1
    const dataArray = [66, ...calCurrent, 0, 0, 0, 0, 0]
    CommandCenter(canId, messageId, dataArray)
  }
  function sendCalVoltage() {
    const canId = integerTo4ByteArray(285021514)
    const messageId = 1
    const dataArray = [65, ...calVoltage, 0, 0, 0, 0, 0]
    CommandCenter(canId, messageId, dataArray)
  }
  function convertCamelCaseToTitle(input) {
    return input
        .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
        .replace(/(\d+)/g, ' $1') 
        .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter
        .trim(); // Remove any leading/trailing spaces
}
  if (chargerDataRecieved) {
    dataObject.currentRef = byteArrayToInt2([chargerDataRecieved[9], chargerDataRecieved[10]]) / 100
    dataObject.voltageRef = byteArrayToInt2([chargerDataRecieved[11], chargerDataRecieved[12]]) / 100
    dataObject.current = byteArrayToInt2([chargerDataRecieved[13], chargerDataRecieved[14]]) / 100 + ' A'
    dataObject.voltage = byteArrayToInt2([chargerDataRecieved[15], chargerDataRecieved[16]]) / 100 + ' V'
  }
  if (chargerDetailsDataRecieved) {
    chargerDetailsObject.hardwareVer = byteArrayToUnsignedInt1([chargerDetailsDataRecieved[9]]) / 10
    chargerDetailsObject.firmwareVer = byteArrayToUnsignedInt1([chargerDetailsDataRecieved[10]]) / 10
    chargerDetailsObject.designedVoltage = byteArrayToUnsignedInt1([chargerDetailsDataRecieved[11]]) + ' V'
    chargerDetailsObject.designedCurrent = byteArrayToUnsignedInt1([chargerDetailsDataRecieved[12]]) + ' A'
  }
  if (chargerStateDataRecieved) {
    chargerStateObject.gfciFault = bitShift(chargerStateDataRecieved[9], 0) ? 'Yes' : 'No'
    chargerStateObject.overTempFault = bitShift(chargerStateDataRecieved[9], 1) ? 'Yes' : 'No'
    chargerStateObject.overCurrentFault = bitShift(chargerStateDataRecieved[9], 2) ? 'Yes' : 'No'
    chargerStateObject.shortCircuitFault = bitShift(chargerStateDataRecieved[9], 3) ? 'Yes' : 'No'
    chargerStateObject.overVoltageFault = bitShift(chargerStateDataRecieved[9], 4) ? 'Yes' : 'No'
    chargerStateObject.underVoltageFault = bitShift(chargerStateDataRecieved[9], 5) ? 'Yes' : 'No'
    chargerStateObject.acUnderVoltageFault = bitShift(chargerStateDataRecieved[9], 6) ? 'Yes' : 'No'
    chargerStateObject.acOverVoltageFault = bitShift(chargerStateDataRecieved[9], 7) ? 'Yes' : 'No'
    chargerStateObject.pfcFault = bitShift(chargerStateDataRecieved[10], 0) ? 'Yes' : 'No'
    chargerStateObject.canCommFail = bitShift(chargerStateDataRecieved[10], 1) ? 'Yes' : 'No'
    chargerStateObject.chargingHaltedFault = bitShift(chargerStateDataRecieved[10], 2) ? 'Yes' : 'No'
    chargerStateObject.chargingNotStartedFault = bitShift(chargerStateDataRecieved[10], 3) ? 'Yes' : 'No'
    chargerStateObject.chargerState = chargerStateEnum[byteArrayToUnsignedInt1([chargerStateDataRecieved[11]])]
    chargerStateObject.chargingMode = chargingModeEnum[byteArrayToUnsignedInt1([chargerStateDataRecieved[12]])]
    chargerStateObject.CANBaudrate = CANBaudRateEnum[byteArrayToUnsignedInt1([chargerStateDataRecieved[13]])]
  }
  if (chargerACDCParameter1DataRecieved) {
    chargerACDCParameter1Object.acMains = byteArrayTounsignedInt2([chargerACDCParameter1DataRecieved[9], chargerACDCParameter1DataRecieved[10]]) / 10 + ' V'
    chargerACDCParameter1Object.temp2 = byteArrayToInt1([chargerACDCParameter1DataRecieved[11]]) + ' °C'
    chargerACDCParameter1Object.temp3 = byteArrayToInt1([chargerACDCParameter1DataRecieved[12]]) + ' °C'
    chargerACDCParameter1Object.tempIn = byteArrayToInt1([chargerACDCParameter1DataRecieved[13]]) + ' °C'
  }
  return (
    <div>
      <Row>
        {Object.keys(dataObject).map((val, i) =>
          <Col xs="12" sm="12" md="3" lg="3" xl="3">
            <Card className="hero-cards">
              <div className="card-head">{convertCamelCaseToTitle(val)}</div>
              <div className="card-foot">
                {dataObject[val]}
              </div>
            </Card>
          </Col>
        )}
        {Object.keys(chargerDetailsObject).map((val, i) =>
          <Col xs="12" sm="12" md="3" lg="3" xl="3">
            <Card className="hero-cards">
              <div className="card-head">{convertCamelCaseToTitle(val)}</div>
              <div className="card-foot">
                {chargerDetailsObject[val]}
              </div>
            </Card>
          </Col>
        )}

        {Object.keys(chargerACDCParameter1Object).map((val, i) =>
          <Col xs="12" sm="12" md="3" lg="3" xl="3">
            <Card className="hero-cards">
              <div className="card-head">{convertCamelCaseToTitle(val)}</div>
              <div className="card-foot">
                {chargerACDCParameter1Object[val]}
              </div>
            </Card>
          </Col>
        )}
      </Row>
      <hr />
      <Row>
        {Object.keys(chargerStateObject).map((val, i) =>
          <Col xs="12" sm="12" md="3" lg="3" xl="3">
            <Card className="hero-cards">
              <div className="card-head">{convertCamelCaseToTitle(val)}</div>
              <div className="card-foot">
                {chargerStateObject[val]}
              </div>
            </Card>
          </Col>
        )}

      </Row>
      <strong>Input Current (A): </strong>
      <input type='text' placeholder='Current Here' onChange={(e) => setCalCurrent(integerTo2ByteArray(e.target.value * 100))}></input>
      <Button onClick={sendCalCurrent}>Set</Button>
      <br />
      <strong>Input Voltage (V): </strong>
      <input type='text' placeholder='Current Voltage' onChange={(e) => setCalVoltage(integerTo2ByteArray(e.target.value * 100))}></input>
      <Button onClick={sendCalVoltage}>Set</Button>
    </div>
  )
}

export default ChargerCalibration